import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';

import {
  getOrdersAction,
  viewOrderAction,
  searchItemAction,
} from '../../../../store/actions/OrderActions';
import {DateTime} from 'luxon';

const OrderTable = () => {
  const allOrders = useSelector((state) => state.orders.orders);
  const searchedOrders = useSelector((state) => state.orders.searchedOrders);
  const orderSearchQuery = useSelector((state) => state.orders.searchQuery);
  const viewOrder = useSelector((state) => state.orders.viewOrder);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersAction());
  }, []);

  //Modal box
  const [addCard, setAddCard] = useState(false);

  // Edit function button click to edit
  const handleCloseModel = () => {
    setAddCard(false);
  };

  // Search System
  const searchHandle = (query) => {
    if (query !== '' && query.length >= 1) {
      if (query.includes('restricted')) {
        query = '*';
      }
      dispatch(searchItemAction(query));
    } else {
      dispatch(searchItemAction(''));
    }
  };
  const searchButtonHandle = (e) => {
    const {search} = e.target;
    if (search.value !== '') {
      searchHandle(search.value);
    }
  };

  return (
    <>
      <div className="col-12 p-0">
        <Modal className="modal fade" show={addCard} onHide={handleCloseModel}>
          <div className="" role="document">
            <div className="modal-header bg-secondary shadow">
              <h4 className="modal-title fs-20 text-white">Order Details</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCloseModel()}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="row px-5 pt-4 pb-2">
              <div className="col">
                <h5>Name:</h5>
              </div>
              <div className="col text-end">
                <span className="fs-5 text-black">{viewOrder?.name}</span>
              </div>
            </div>
            <div className="row px-5 py-2">
              <div className="col">
                <h5>Email:</h5>
              </div>
              <div className="col text-end">
                <span className="fs-5 text-black">{viewOrder?.email}</span>
              </div>
            </div>
            {viewOrder?.message ? (
              <div className="row px-5 py-2">
                <div className="mb-2">
                  <h5>Message:</h5>
                </div>
                <div className="">
                  <p className="text-red font-w600 px-3 py-2 bg-light border-dark border border-2 border-top-0 border-end-0 border-bottom-0">
                    {viewOrder?.message}
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row px-5 py-2">
              <div className="mb-2">
                <h5>Ordered Items:</h5>
              </div>
              <div className="">
                <table className="table table-striped" width="100%">
                  <thead>
                    <tr>
                      <th scope="col">
                        <small>Image</small>
                      </th>
                      <th scope="col">
                        <small>Item</small>
                      </th>
                      <th scope="col">
                        <p className="m-0 p-0 text-center">
                          <small>Qty</small>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewOrder?.items.map((item, itemIndex) => (
                      <tr className="text-black" key={itemIndex}>
                        <td width="100">
                          <img
                            src={item.image || '/default-logo.jpg'}
                            height="55"
                            width="55"
                            className="rounded"
                          />
                        </td>
                        <td width="200">
                          <span
                            style={{
                              maxWidth: '200px',
                              whiteSpace: 'break-spaces',
                            }}
                            className="d-block"
                          >
                            {item.title}
                          </span>
                        </td>
                        <td className="text-center">{item.number}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-secondary-1 shadow">
                    <tr>
                      <th scope="col" colSpan="2" className="text-black ">
                        Total IP Food Items
                      </th>
                      <th
                        scope="col"
                        className="text-secondary bold fs-5 heavy text-end"
                      >
                        {viewOrder?.totalItems}
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="text-black ">
                        Total
                      </th>
                      <td></td>
                      <th
                        scope="col"
                        className="text-secondary bold fs-5 heavy text-end"
                      >
                        ${viewOrder?.total}
                      </th>
                    </tr>
                    {viewOrder?.coupon ? (
                      <tr>
                        <th scope="col" className="text-black ">
                          Coupon
                        </th>
                        <td></td>
                        <th
                          scope="col"
                          className="text-secondary bold fs-5 heavy text-end"
                        >
                          -
                          {viewOrder?.coupon?.type == 'fixed'
                            ? '$' + viewOrder.coupon.price
                            : '%' + viewOrder.coupon.price}
                        </th>
                      </tr>
                    ) : (
                      ''
                    )}
                    <tr className="bg-secondary">
                      <th scope="col" className="text-white ">
                        Total Amount
                      </th>
                      <td></td>
                      <th
                        scope="col"
                        className="text-white bold fs-5 heavy text-end"
                      >
                        ${viewOrder?.price ? viewOrder.price : '0.00'}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setAddCard(false);
                }}
                className="btn btn-secondary shadow"
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
        <div className="card">
          <div className="card-header gradient-dark">
            <div>
              <h1 className="display-6 text-white">
                All Orders <br />
              </h1>
              <h5 className="text-white">Total - {allOrders.length}</h5>
            </div>
          </div>
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchButtonHandle(e);
              }}
            >
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Order Name"
                  aria-label="Order Name"
                  aria-describedby="button-addon2"
                  name="search"
                  id="search"
                  defaultValue={orderSearchQuery}
                  onChange={(e) => searchHandle(e.target.value)}
                />
                <button
                  className="btn btn-secondary "
                  type="submit"
                  id="button-addon2"
                >
                  Search
                </button>
              </div>
              {orderSearchQuery && (
                <input
                  type="reset"
                  onClick={() => {
                    searchHandle('');
                  }}
                  className="btn btn-secondary btn-lg py-2 px-4 rounded-pill"
                  value={'Show All'}
                />
              )}
            </form>
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Price</th>
                      {/* <th>Note</th> */}
                      <th>Items</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedOrders.map((content, index) => (
                      <tr key={index} className="hover-bg-secondary-1">
                        <td>
                          <span className="text-black badge bg-secondary-1 text-white">
                            {DateTime.fromISO(
                              content.orderDateTime
                            ).toLocaleString(DateTime.DATE_MED)}
                            <br></br>
                            <small>
                              {DateTime.fromISO(content.orderDateTime).toFormat(
                                'hh:mm a'
                              )}
                            </small>
                          </span>
                        </td>
                        <td width="150">{content.name}</td>
                        <td width="150">{content.email}</td>
                        <td className="bold text-black" width="150">
                          ${content.price ? content.price : '0.00'}
                        </td>
                        <td width="200">
                          <ul
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {content.items.map((item, indexItems) => (
                              <li key={indexItems} style={{fontSize: '12px'}}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2.5}
                                  stroke="var(--secondary)"
                                  className="me-1"
                                  height="10px"
                                  width="10px"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                  />
                                </svg>
                                {item.title} -- x{item.number}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Link
                              className="btn btn-secondary shadow me-2 px-3"
                              onClick={(event) => {
                                setAddCard(true);
                                dispatch(viewOrderAction(content));
                              }}
                              title="View Order"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 me-1"
                                height="14"
                                width="14"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                              </svg>{' '}
                              <small>View</small>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderTable;
